import React, { useState, useEffect } from "react";
import { useStatePersist } from "use-state-persist";

import fetchJsonp from "fetch-jsonp";
import SmartFeedJobAlerts from "@accrosoft-ltd/vf-careers-site-theme/src/components/SmartFeedJobAlerts_WT";

import { Link } from "gatsby";

import "url-search-params-polyfill";

require("es6-promise").polyfill();

let prevCount = 0;
let currentCount = 0;
let keywordTime = null;

const setVacancyLoadingBackup = function setVacancyLoadingBackup(
  loading,
  vacancyLoading
) {
  if (loading) {
    return true;
  }

  return false;
};

const SmartFeedFiltersComponent = ({
  siteConfig,
  vacancyLoading = true,
  apiKey = "",
  gdprKey = "",
  group = false,
  CompanyGroupName = "",
  OptInLink = "",
  UnsubscribeLink = "",
  VacancyPageBaseURL = "",
  ReturnToSearchURL = "",
  location = true,
  region = false,
  category = true,
  industry = false,
  jobType = true,
  jobTime = true,
  contentDataData = [],
  setVacancyLoading = setVacancyLoadingBackup,
  smartfeedCompanyData = {},
  trackEvent,
}) => {
  const id = 1;
  const [vacancy, setVacancy] = useState([]);
  const [vacancyTitle, setVacancyTitle] = useState("Loading vacancies...");
  const [isIinitialLoad, setIsIinitialLoad] = useState(true);
  const [initialLoading, setInitialLoading] = useState(true);

  // Filter States
  const [locationFilter, setLocationFilter] = useState([]);
  const [regionFilter, setRegionFilter] = useState([]);
  const [categoryFilter, setCategoryFilter] = useState([]);
  const [industryFilter, setIndustryFilter] = useState([]);
  const [postingTypeFilter, setPostingTypeFilter] = useState([]);
  const [jobTypeFilter, setJobTypeFilter] = useState([]);
  const [keywordFilter, setKeywordFilter] = useState([]);
  const [autoCompleteFilter, setAutoCompleteFilter] = useState([]);
  const [autocompleteListHide, setAutocompleteListHide] = useState(true);
  const [filterState, setFilterState] = useState({});
  const [savedJobs, setSavedJobs] = useStatePersist("@savedJobs_" + apiKey, []);

  const [sortBySelection, setSortBySelection] = useStatePersist(
    "@sortBy",
    "postingdate"
  );
  const [sortByDirSelection, setSortByDirSelection] = useStatePersist(
    "@sortDir",
    "desc"
  );

  const [viewBySelection, setViewBySelection] = useStatePersist(
    "@sortView",
    "grid"
  );

  const [vacsCount, setVacsCount] = useState(0);
  const [loading, setLoading] = useState(true);
  const [loadMoreText, setLoadMoreText] = useState(false);

  // Button filters
  const [careersNavState, setCareersNavState] = useState("careers");

  let groupOrIdParam = "id";

  if (group === true) {
    groupOrIdParam = "group";
  }

  function unsaveJob(id) {
    const newSavedJobsArray = savedJobs.filter((e) => e !== id.toString());
    setSavedJobs(newSavedJobsArray);
    if (trackEvent) {
      trackEvent({
        eventCategory: "SmartFeedListComponent",
        eventAction: "unsaveJob",
        eventLabel: encodeURIComponent(id),
      });
    }
  }

  function saveJob(id) {
    setSavedJobs((savedJobsOld) => [...savedJobsOld, id]);
    if (trackEvent) {
      trackEvent({
        eventCategory: "SmartFeedListComponent",
        eventAction: "saveJob",
        eventLabel: encodeURIComponent(id),
      });
    }
  }

  function removeDuplicates(array) {
    return array.filter((a, b) => array.indexOf(a) === b);
  }

  const fetchFilters = async (name, setState, label) => {
    try {
      fetchJsonp(
        siteConfig.sfAPIEndpoint +
          "/CareerPage/" +
          name +
          "?" +
          groupOrIdParam +
          "=" +
          apiKey +
          `${
            name === "GetLocationListByGroup" || name === "GetLocations"
              ? "&region=All"
              : ""
          }`,
        {
          timeout: 15000,
        }
      )
        .then(function (response) {
          return response.json();
        })
        .then(function (jsonList) {
          // let temp = ["All " + label]
          let temp = ["All " + label];

          setState(temp.concat(removeDuplicates(jsonList)));

          setVacancyLoading(false);
        })
        .catch(function (ex) {
          setLoading(false);
          setVacancyLoading(false);
          console.error(ex, "error");
        });
    } catch (error) {
      setLoading(false);
      setVacancyLoading(false);
      console.error(error.message, "error");
    }
  };

  const fetchVacancies = async (
    state,
    name,
    e,
    loadMore,
    postingTypeSelection
  ) => {
    let postingTypeSelectionValue =
      postingTypeSelection || careersNavState || "careers";

    // State of filters, Name of searched filter, event, load more boolean
    setLoadMoreText("Loading.....");

    if (name) {
      prevCount = 0;
      currentCount = 0;
    }

    let temp = state;

    if (name) {
      if (e) {
        if (e.target && e.target.value.includes(name)) {
          e.target.value = "";
        }
      }

      if (name === "autocomplete") {
        temp.latitude = e.lat;
        temp.longitude = e.long;
        temp.distance = e.distance;
        temp.unit = "Miles";
      } else if (e && e.target && e.target.value) {
        if (name === "location") {
          if (e.target.value.includes("All ")) {
            delete temp.location;
          } else {
            temp.location = e.target.value;
          }
        } else if (name === "keywords") {
          temp.keywords = e.target.value;
        } else if (name === "region") {
          if (e.target.value.includes("All ")) {
            delete temp.region;
          } else {
            temp.region = e.target.value;
          }
        } else if (name === "category") {
          if (e.target.value.includes("All ")) {
            delete temp.category;
          } else {
            temp.category = e.target.value;
          }
        } else if (name === "industry") {
          if (e.target.value.includes("All ")) {
            delete temp.industrysector;
          } else {
            temp.industrysector = e.target.value;
          }
        } else if (name === "postingtype") {
          if (e.target.value.includes("All ")) {
            delete temp.postingtype;
          } else {
            temp.postingtype = e.target.value;
          }
        } else if (name === "jobtype") {
          if (e.target.value.includes("All ")) {
            delete temp.jobtype;
          } else {
            temp.jobtype = e.target.value;
          }
        } else if (name === "jobtime") {
          if (e.target.value.includes("All ")) {
            delete temp.jobtime;
          } else {
            temp.jobtime = e.target.value;
          }
        } else if (name === "country") {
          if (e.target.value.includes("All ")) {
            delete temp.country;
          } else {
            temp.country = e.target.value;
          }
        } else if (name === "companySearch") {
          if (e.target.value.includes("All ")) {
            delete temp.CompanySearch;
          } else {
            temp.CompanySearch = e.target.value;
          }
        }
      } else {
        if (name === "reset") {
          temp = {};
          if (document && document.getElementById("keywords")) {
            document.getElementById("keywords").value = "";
          }
          if (document && document.getElementById("autocomplete")) {
            document.getElementById("autocomplete").value = "";
          }
        } else if (name === "keywords") {
          if (document && document.getElementById("keywords")) {
            document.getElementById("keywords").value = "";
          }
          delete temp.keywords;
        } else if (name === "autocomplete") {
          if (document && document.getElementById("autocomplete")) {
            document.getElementById("autocomplete").value = "";
          }
          delete temp.location;
          delete temp.latitude;
          delete temp.longitude;
          delete temp.distance;
          delete temp.units;
        }
      }

      setFilterState(temp);
    }

    function getAdvertData(jobs, prevCount, currentCount) {
      return Promise.all(
        jobs.slice(prevCount, currentCount).map(async (id) => {
          return fetchJsonp(
            siteConfig.sfAPIEndpoint + "/CareerPage/GetItem?id=" + id,
            {
              timeout: 15000,
            }
          ).then(function (response) {
            return response.json();
          });
        })
      );
    }

    if (!loadMore) {
      prevCount = 0;
      currentCount = 0;
      setLoading(true);
    }

    try {
      const postingTypeValue =
        temp.postingtype || postingTypeSelectionValue || "careers";

      fetchJsonp(
        siteConfig.sfAPIEndpoint +
          "/CareerPage/GetResultList?" +
          groupOrIdParam +
          "=" +
          apiKey +
          "&" +
          "sortby=" +
          sortBySelection +
          "&" +
          "sortdir=" +
          sortByDirSelection +
          "&" +
          "postingtype=" +
          postingTypeValue +
          "&" +
          buildQuery(temp),
        {
          timeout: 15000,
        }
      )
        .then(function (response) {
          return response.json();
        })
        .then(async function (jsonList) {
          prevCount = currentCount;
          currentCount = currentCount + 12;

          await getAdvertData(jsonList, prevCount, currentCount).then(function (
            data
          ) {
            if (loadMore) {
              setVacancy(vacancy.concat(data));
            } else {
              setVacancy(data);
            }

            if (jsonList.length === 0) {
              setVacancyTitle(
                "We couldn't find any vacancies matching your search"
              );
            } else if (jsonList.length === 1) {
              setVacancyTitle("We have " + jsonList.length + " vacancy");
            } else {
              setVacancyTitle("We have " + jsonList.length + " vacancies");
            }

            setInitialLoading(false);

            setLoading(false);
            setVacancyLoading(false);

            setLoadMoreText("Load More");
            setVacsCount(jsonList.length);
          });
        });
    } catch (error) {
      setLoading(false);
      setVacancyLoading(false);
      console.error(error.message, "error");
    }
  };

  const getCurrentLocation = function getCurrentLocation() {
    if (navigator.geolocation) {
      var optionsPosition = {
        enableHighAccuracy: true,
        timeout: 5000,
        maximumAge: 0,
      };

      function errorPosition(err) {
        console.error(`We couldn't retrieve your location`, err);
      }

      function showPosition(position) {
        fetchVacancies(
          filterState,
          "autocomplete",
          {
            lat: position.coords.latitude,
            long: position.coords.longitude,
            distance: 15,
            autocomplete: "Your Location",
          },
          false
        );
      }

      navigator.geolocation.getCurrentPosition(
        showPosition,
        errorPosition,
        optionsPosition
      );
    } else {
      console.warn("Geolocation is not supported by your browser");
    }
  };

  const chooseGeoLocation = function chooseGeoLocation(
    lat,
    long,
    distance,
    name
  ) {
    setAutocompleteListHide(true);
    fetchVacancies(
      filterState,
      "autocomplete",
      {
        lat: lat,
        long: long,
        distance: distance,
        autocomplete: name,
      },
      false
    );
  };

  const addActiveClassnameToNav = function addActiveClassnameToNav(stateName) {
    if (stateName === careersNavState) {
      return "VF_Nav_Item_Active";
    }

    return "";
  };

  const clickCurrentVacancies = function clickCurrentVacancies() {
    setCareersNavState("careers");
    fetchVacancies(filterState, undefined, undefined, false, "careers");
  };

  const clickRegisterVacancies = function clickRegisterVacancies() {
    setCareersNavState("register");
    fetchVacancies(filterState, undefined, undefined, false, "register");
  };

  const clickAlertsVacancies = function clickAlertsVacancies() {
    setCareersNavState("alerts");
  };

  const renderFilter = function renderFilter(data, label, stateName) {
    if (label === "Keyword Search") {
      return (
        <div className="vf-filter">
          <label htmlFor={"select-" + label}>{label}: </label>
          <input
            defaultValue={filterState[stateName]}
            onChange={(e) => {
              e.persist();
              keywordSearchChange(filterState, stateName, e, false);
            }}
            className="VF-form-control onchange-search form-control"
            id={"keywords"}
            name={label}
            placeholder="Enter keywords"
            type="text"
          />
        </div>
      );
    } else if (label === "Location Search") {
      return (
        <div className="vf-filter">
          <label htmlFor={"select-" + label}>{label}: </label>
          <div className="vf-filter-loc-complete-wrap">
            <input
              value={filterState[stateName]}
              onChange={(e) =>
                autocompleteSearchChange(filterState, label, e, false)
              }
              className="VF-form-control onchange-search form-control"
              id={"autocomplete"}
              name={label}
              placeholder="Enter postcode, town or city"
              type="text"
            />
            {!autocompleteListHide &&
              data &&
              Array.isArray(data) &&
              data.length > 0 && (
                <div className="vf-filter-loc-complete-list">
                  {data.map((loc, i) => (
                    <div
                      key={i}
                      onClick={() =>
                        chooseGeoLocation(
                          loc.latitude,
                          loc.longitude,
                          10,
                          loc.name
                        )
                      }
                    >
                      {loc.displayName}
                    </div>
                  ))}
                </div>
              )}
            <div id="autocomplete-icon">
              <span
                aria-hidden="true"
                onClick={() => getCurrentLocation()}
                className="far fa-dharmachakra right-icon autocomplete-get-location"
                title="Get your location"
              ></span>
              <span
                aria-hidden="true"
                className="fas fa-undo right-icon hidden autocomplete-reset-location"
                title="Reset Location"
              ></span>
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div className="vf-filter">
          <label htmlFor={"select-" + stateName}>{label}: </label>
          <select
            className="form-control"
            value={filterState[stateName] || data[0]}
            onChange={(e) => fetchVacancies(filterState, stateName, e, false)}
            name={"select-" + stateName}
            id={"select-" + stateName}
          >
            {data.map((item, i) => (
              <option key={i} value={item}>
                {item}
              </option>
            ))}
          </select>
        </div>
      );
    }
  };

  const loadMore = function loadMore() {
    fetchVacancies(filterState, undefined, undefined, true);
    if (trackEvent) {
      trackEvent({
        eventCategory: "SmartFeedListComponent",
        eventAction: "loadMore",
        eventLabel: "Loaded more vacancies",
      });
    }
  };

  const resetVacs = function resetVacs() {
    fetchVacancies({}, "reset", undefined, false);
    if (trackEvent) {
      trackEvent({
        eventCategory: "SmartFeedListComponent",
        eventAction: "resetVacs",
        eventLabel: "Reset the filters",
      });
    }
  };

  const searchVacs = function searchVacs() {
    fetchVacancies(filterState, "search", undefined, false);
    if (trackEvent) {
      trackEvent({
        eventCategory: "SmartFeedListComponent",
        eventAction: "searchVacs",
        eventLabel: "Triggered a search",
      });
    }
  };

  useEffect(() => {
    if (!isIinitialLoad) {
      searchVacs();
    }
  }, [sortBySelection, sortByDirSelection]);

  const autocompleteSearchChange = function autocompleteSearchChange(
    state,
    name,
    e,
    loadMore
  ) {
    setAutocompleteListHide(false);

    let val = e.currentTarget.value;

    if (e.currentTarget.value.length > 3) {
      // Do api call to get key
      fetchJsonp(
        siteConfig.sfAPIEndpoint + "/CareerPage/GetLocationAPIKey?id=" + apiKey,
        {
          timeout: 15000,
        }
      )
        .then(function (response) {
          return response.json();
        })
        .then(function (geoKey) {
          // Do api call to get locs
          fetch(
            siteConfig.geocodingEndpoint +
              "/api/V2/exact/?apikey=" +
              geoKey.key +
              "&search=" +
              encodeURIComponent(val)
          )
            .then((res) => res.json())
            .then((result) => {
              setAutoCompleteFilter(result.locationResults);
            });
        });
    } else {
      if (autoCompleteFilter?.length) {
        resetVacs();
      }
      setAutocompleteListHide(true);
      setAutoCompleteFilter([]);
    }
  };

  const keywordSearchChange = function keywordSearchChange(
    state,
    name,
    e,
    loadMore
  ) {
    clearTimeout(keywordTime);

    keywordTime = setTimeout(() => {
      fetchVacancies({ ...filterState, ...state }, name, e, loadMore);
    }, 500);
  };

  const getSummary = function getSummary(summary, description) {
    if (summary) {
      return summary;
    } else {
      let alteredDescription = description.replace(/<[^>]*>?/gm, "");
      let tempSummary = alteredDescription.substring(0, 397);
      if (tempSummary.length === 397) {
        tempSummary += "...";
      }
      return tempSummary;
    }
  };

  const calculateSalary = function calculateSalary(
    Currency,
    SalaryFrom,
    SalaryTo,
    SalaryType,
    SalaryPeriod
  ) {
    function numberWithCommas(x) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    let NewSalary;
    let NewSalaryFrom;
    let NewSalaryTo;

    let currencyFormatted = Currency;

    if (Currency === "&pound;") {
      currencyFormatted = "£";
    }

    if (SalaryTo === 0) {
      NewSalary = SalaryType;
    } else if (SalaryFrom === SalaryTo) {
      if (SalaryFrom >= 1000) {
        // Remove decimals
        NewSalaryFrom =
          currencyFormatted + numberWithCommas(SalaryFrom.toFixed());
      } else {
        // Add two decimals
        NewSalaryFrom =
          currencyFormatted + numberWithCommas(SalaryFrom.toFixed(2));
      }
      NewSalary = NewSalaryFrom + " " + SalaryPeriod;
    } else {
      if (SalaryFrom >= 1000) {
        NewSalaryFrom =
          currencyFormatted + numberWithCommas(SalaryFrom.toFixed());
      } else {
        NewSalaryFrom =
          currencyFormatted + numberWithCommas(SalaryFrom.toFixed(2));
      }
      if (SalaryTo >= 1000) {
        NewSalaryTo = currencyFormatted + numberWithCommas(SalaryTo.toFixed());
      } else {
        NewSalaryTo = currencyFormatted + numberWithCommas(SalaryTo.toFixed(2));
      }

      NewSalary = NewSalaryFrom + " - " + NewSalaryTo + " " + SalaryPeriod;
    }
    return NewSalary;
  };

  const FormatDateTime = function FormatDateTime(JSONDate) {
    // Set default values to avoid function breaking
    var expDate = new Date(parseInt(JSONDate.substr(6)));
    var dateFormatOptions = new Object({});
    dateFormatOptions.weekday = "long";
    dateFormatOptions.year = "numeric";
    dateFormatOptions.month = "short";
    dateFormatOptions.day = "numeric";
    return expDate.toLocaleDateString(undefined, dateFormatOptions);
  };

  const buildQuery = function buildQuery(data) {
    if (typeof data === "string") return data;
    let query = [];

    for (let key in data) {
      if (data.hasOwnProperty(key)) {
        query.push(
          encodeURIComponent(key) + "=" + encodeURIComponent(data[key])
        );
      }
    }

    return query.join("&");
  };

  const slugify = function slugify(text) {
    return text
      .toString() // Cast to string
      .toLowerCase() // Convert the string to lowercase letters

      .trim() // Remove whitespace from both sides of a string
      .replace(/\s+/g, "-") // Replace spaces with -
      .replace(/[^\w\-]+/g, "") // Remove all non-word chars
      .replace(/\-\-+/g, "-"); // Replace multiple - with single -
  };

  useEffect(() => {
    if (isIinitialLoad) {
      if (trackEvent) {
        trackEvent({
          eventCategory: "SmartFeedListComponent",
          eventAction: "initalised",
          eventLabel: apiKey,
        });
      }

      if (URLSearchParams) {
        var urlParams = new URLSearchParams(window.location.search);
        var entries = urlParams.entries();
      }
      let temp = {};
      for (let pair of entries) {
        temp[pair[0]] = pair[1];
      }

      setFilterState({ ...filterState, ...temp });

      const timeOut = setTimeout(() => {
        fetchVacancies(
          { ...filterState, ...temp },
          undefined,
          undefined,
          false
        );

        if (siteConfig.showAdvancedFilters === true) {
          if (group === true) {
            fetchFilters(
              "GetLocationListByGroup",
              setLocationFilter,
              "Locations"
            ); // Locations
            fetchFilters("GetRegionListByGroup", setLocationFilter, "Regions"); // Regions
            fetchFilters(
              "GetCategoryListByGroup",
              setCategoryFilter,
              "Categories"
            ); // Categories
            fetchFilters(
              "GetIndustryListByGroup",
              setIndustryFilter,
              "Industries"
            ); // Industries
            fetchFilters(
              "GetPostingTypesListByGroup",
              setPostingTypeFilter,
              "Posting Types"
            ); // Posting Types
            fetchFilters(
              "GetJobTypeListByGroup",
              setJobTypeFilter,
              "Job Types"
            ); // Job Types
          } else {
            fetchFilters("GetLocations", setLocationFilter, "Locations"); // Locations
            fetchFilters("GetRegions", setRegionFilter, "Regions"); // Regions
            fetchFilters("GetCategories", setCategoryFilter, "Categories"); // Categories
            fetchFilters("GetIndustries", setIndustryFilter, "Industries"); // Industries
            fetchFilters(
              "GetPostingTypes",
              setPostingTypeFilter,
              "Posting Types"
            ); // Posting Types
            fetchFilters("GetJobTypes", setJobTypeFilter, "Job Types"); // Job Types
          }
        }

        setIsIinitialLoad(false);
      }, 50);
      return () => clearTimeout(timeOut);
    }
  }, [careersNavState, isIinitialLoad]);

  return (
    <div className={vacancyLoading ? "vacancies-landing-loading" : ""}>
      {!vacancyLoading &&
      smartfeedCompanyData &&
      smartfeedCompanyData.CompanyName ? (
        <>
          <section
            className={`main-vacs-wrap sorrtbox_viewby_${viewBySelection} sorrtbox_sortby_${sortBySelection} sorrtbox_sortdirby_${sortByDirSelection}`}
          >
            <div>
              <div className="container">
                <div className="row clearfix">
                  <div className="col-12 column job-vacancies-col">
                    <div className="container filters-container">
                      <div className="filters">
                        <div className="page-search-input-group w-100">
                          <div className="col-12 col-lg-6">
                            {renderFilter(
                              keywordFilter,
                              "Keyword Search",
                              "keywords"
                            )}
                          </div>
                          <div className="col-12 col-lg-6">
                            {renderFilter(
                              autoCompleteFilter,
                              "Location Search",
                              "autocomplete"
                            )}
                          </div>
                          <div className="page-search-input-group page-search-input-group-extravacs w-100">
                            <div
                              onClick={() => {
                                var e = { target: { value: "Head Office" } };

                                fetchVacancies(
                                  filterState,
                                  "category",
                                  e,
                                  false
                                );
                              }}
                              className="col-12 col-lg-3"
                            >
                              <div className="department">
                                <img
                                  src="https://cdn2.accropress.com/content-uploads/d9af115f-2509-4ab5-a384-46c3b2ba2ee1/global/QkOTWQkpTW6FFOycea3c_small-head-office.jpg"
                                  alt="Head Office"
                                />
                                <div className="department-name">
                                  <div>Head Office</div>
                                  <div>Click here to apply</div>
                                </div>
                              </div>
                            </div>
                            <div
                              onClick={() => {
                                var e = { target: { value: "Logistics" } };

                                fetchVacancies(
                                  filterState,
                                  "category",
                                  e,
                                  false
                                );
                              }}
                              className="col-12 col-lg-3"
                            >
                              <div className="department">
                                <img
                                  src="https://cdn2.accropress.com/content-uploads/d9af115f-2509-4ab5-a384-46c3b2ba2ee1/global/k2IdICgxT2SZvwFkvAfF_small-logistis.jpg"
                                  alt="Logistics"
                                />
                                <div className="department-name">
                                  <div>Logistics</div>
                                  <div>Click here to apply</div>
                                </div>
                              </div>
                            </div>
                            <div
                              onClick={() => {
                                var e = { target: { value: "Retail" } };

                                fetchVacancies(
                                  filterState,
                                  "category",
                                  e,
                                  false
                                );
                              }}
                              className="col-12 col-lg-3"
                            >
                              <div className="department">
                                <img
                                  src="https://cdn2.accropress.com/content-uploads/d9af115f-2509-4ab5-a384-46c3b2ba2ee1/global/cFtTaQhLRN6o4O8uZ3vy_small-retail.jpg"
                                  alt="Retail"
                                />
                                <div className="department-name">
                                  <div>Retail</div>
                                  <div>Click here to apply</div>
                                </div>
                              </div>
                            </div>
                            <div
                              onClick={() => {
                                fetchVacancies(
                                  filterState,
                                  "reset",
                                  undefined,
                                  false
                                );
                              }}
                              className="col-12 col-lg-3"
                            >
                              <div className="department">
                                <img
                                  src="https://cdn2.accropress.com/content-uploads/d9af115f-2509-4ab5-a384-46c3b2ba2ee1/global/b54Jub5lSuCFT5cGXhga_small-all-vacancies.jpg"
                                  alt="Vacancies"
                                />
                                <div className="department-name">
                                  <div>All Vacancies</div>
                                  <div>Click here to apply</div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* {siteConfig.showAdvancedFilters && (
                  <div className="page-search-input-group page-search-input-group-advanced w-100">
                    <div className="col-12 col-lg-4">
                      {renderFilter(
                        postingTypeFilter,
                        "Filter by Posting Type",
                        "postingtype"
                      )}
                    </div>
                    <div className="col-12 col-lg-4">
                      {renderFilter(
                        locationFilter,
                        "Filter by Location",
                        "location"
                      )}
                    </div>
                    <div className="col-12 col-lg-4">
                      {renderFilter(
                        regionFilter,
                        "Filter by Region",
                        "region"
                      )}
                    </div>
                    <div className="col-12 col-lg-4">
                      {renderFilter(
                        industryFilter,
                        "Filter by Industry",
                        "industry"
                      )}
                    </div>
                    <div className="col-12 col-lg-4">
                      {renderFilter(
                        jobTypeFilter,
                        "Filter by Job Type",
                        "jobtype"
                      )}
                    </div>
                    <div className="col-12 col-lg-4">
                      {renderFilter(
                        categoryFilter,
                        "Filter by Category",
                        "category"
                      )}
                    </div>
                  </div>
                )} */}
                      </div>
                    </div>

                    <h3 className="text-center vacancies-landing__header__heading">
                      {vacancyTitle}{" "}
                      {filterState.category
                        ? `for ${filterState.category}`
                        : ""}
                    </h3>

                    {siteConfig.displayVacanciesSortOptions && (
                      <div className="vacancies-landing__header__sortbox">
                        <div className="vacancies-landing__header__sortbox__col sortbox__col_left">
                          <div className="sorbox__viewby_wrap">
                            <span className="sortbox__label">View by:</span>
                            <span className="sortbox__contents">
                              <button
                                onClick={() => setViewBySelection("list")}
                                title="List View"
                                className="sortbox__button sortbox_view__button"
                              >
                                <span className="fas fa-grip-lines"></span>
                              </button>
                              <button
                                onClick={() => setViewBySelection("grid")}
                                title="Grid View"
                                className="sortbox__button sortbox_view__button"
                              >
                                <span className="fas fa-grip-horizontal"></span>
                              </button>
                            </span>
                          </div>
                        </div>
                        <div className="vacancies-landing__header__sortbox__col sortbox__col_right">
                          <div className="sortbox__select_wrap">
                            <label
                              htmlFor="vf-vacs-sort-by"
                              className="sortbox__label"
                            >
                              Sort by:
                            </label>
                            <select
                              id="vf-vacs-sort-by"
                              className="VF-form-control onchange-search form-control"
                              onChange={(e) =>
                                setSortBySelection(e.target.value)
                              }
                              placeholder={"Please select an option"}
                              title={"Select an option to sort vacancies by"}
                              selected={sortBySelection}
                            >
                              <option value="postingdate">Posting Date</option>
                              <option value="expirydate">Expiry Date</option>
                              <option value="industry">Industry</option>
                              <option value="category">Category</option>
                              <option value="jobtype">Job Type</option>
                              <option value="region">Region</option>
                              <option value="location">Location</option>
                              <option value="sublocation">Sub Location</option>
                            </select>
                          </div>
                        </div>
                      </div>
                    )}

                    <div className="vf-careers-site">
                      <div id="VF_vacancies">
                        {!vacancyLoading && (
                          <div className="row VF_vacancies_main_wrap">
                            <div className="col-12">
                              {careersNavState !== "alerts" ? (
                                <div className="main-section">
                                  <div className="row search-container-wrap">
                                    <div className="inner-content grid-section box-theme-2 joblist-section col-xs-12 col-12">
                                      <div
                                        id="VF-vacancies-wrapper"
                                        className="col-12 VF_vacancies_list"
                                      >
                                        <div className="row">
                                          {loading ? (
                                            "Loading vacancies..."
                                          ) : (
                                            <>
                                              {vacancy &&
                                              vacancy !== [] &&
                                              vacancy !== "[]" &&
                                              !initialLoading ? (
                                                <>
                                                  {vacancy &&
                                                  vacancy.length > 0 ? (
                                                    vacancy.map((job, i) => (
                                                      <div
                                                        className="job-card card"
                                                        id={new Buffer(
                                                          job.Id
                                                        ).toString("base64")}
                                                        key={i}
                                                      >
                                                        <div className="job-card-details">
                                                          <div className="job-card-title">
                                                            <Link
                                                              to={
                                                                siteConfig.companyCareersSite
                                                                  ? "/vacancies/vacancy/" +
                                                                    new Buffer(
                                                                      job.Id
                                                                    ).toString(
                                                                      "base64"
                                                                    ) +
                                                                    "/" +
                                                                    slugify(
                                                                      job.JobTitle
                                                                    ) +
                                                                    window
                                                                      .location
                                                                      .search
                                                                  : "/vacancies/" +
                                                                    apiKey +
                                                                    "/vacancy/" +
                                                                    new Buffer(
                                                                      job.Id
                                                                    ).toString(
                                                                      "base64"
                                                                    ) +
                                                                    "/" +
                                                                    slugify(
                                                                      job.JobTitle
                                                                    ) +
                                                                    window
                                                                      .location
                                                                      .search
                                                              }
                                                            >
                                                              {job.JobTitle}
                                                            </Link>
                                                          </div>
                                                          <div className="job-card-savejob">
                                                            <div
                                                              title="Save this job for later"
                                                              className="job-card-savejob-trigger"
                                                              role="button"
                                                              onClick={() => {
                                                                if (
                                                                  savedJobs.filter(
                                                                    (e) =>
                                                                      e ==
                                                                      job.Id.toString()
                                                                  ).length > 0
                                                                ) {
                                                                  unsaveJob(
                                                                    job.Id
                                                                  );
                                                                } else {
                                                                  saveJob(
                                                                    job.Id
                                                                  );
                                                                }
                                                              }}
                                                            >
                                                              {savedJobs.filter(
                                                                (e) =>
                                                                  e ==
                                                                  job.Id.toString()
                                                              ).length > 0 ? (
                                                                <span className="fas fa-heart"></span>
                                                              ) : (
                                                                <span className="far fa-heart"></span>
                                                              )}
                                                            </div>
                                                          </div>
                                                          <div className="company-logo">
                                                            <img
                                                              src={
                                                                job.CompanyLogoPath
                                                              }
                                                              alt={job.Company}
                                                            />
                                                          </div>
                                                          <div className="job-card-location">
                                                            {job.Company}
                                                            {" - "}
                                                            {job.Location}
                                                          </div>
                                                          <div className="job-card-summary-logo-wrap">
                                                            <div className="job-card-summary">
                                                              {getSummary(
                                                                job.Summary,
                                                                job.Description
                                                              )}
                                                            </div>
                                                          </div>
                                                          {/* <div className="job-card-benefits">
                                                                    Benefits:{" "}
                                                                    {job.Benefits}
                                                                  </div> */}
                                                          <div className="job-card-reference">
                                                            {"(Reference: " +
                                                              job.AdvertReference +
                                                              ")"}
                                                          </div>
                                                          {/* <div className="job-card-meta">
                                                                    <div className="job-card-meta-item">
                                                                      <span>
                                                                        Location:
                                                              </span>
                                                                      {job.Region
                                                                        ? job.Region +
                                                                        ", "
                                                                        : ""}
                                                                      {job.Location
                                                                        ? job.Location +
                                                                        ", "
                                                                        : ""}
                                                                      {job.SubLocation
                                                                        ? job.SubLocation +
                                                                        ", "
                                                                        : ""}
                                                                      {job.PostCode
                                                                        ? job.PostCode +
                                                                        ", "
                                                                        : ""}
                                                                      {job.Country
                                                                        ? job.Country
                                                                        : ""}
                                                                    </div>
                                                                    <div className="job-card-meta-item">
                                                                      <span>
                                                                        Job Type:
                                                              </span>
                                                                      {job.JobType +
                                                                        " - " +
                                                                        job.JobTypeTime}
                                                                    </div>
                                                                    <div className="job-card-meta-item">
                                                                      <span>
                                                                        Category:
                                                              </span>
                                                                      {job.JobCategory}
                                                                    </div>
                                                                    <div className="job-card-meta-item">
                                                                      <span>
                                                                        Industry:
                                                              </span>
                                                                      {job.Industry}
                                                                    </div>
                                                                    <div className="job-card-meta-item">
                                                                      <span>
                                                                        Salary:
                                                              </span>
                                                                      {calculateSalary(
                                                                        job.Currency,
                                                                        job.SalaryFrom,
                                                                        job.SalaryTo,
                                                                        job.SalaryType,
                                                                        job.SalaryPeriod
                                                                      )}
                                                                    </div>
                                                                    <div className="job-card-meta-item">
                                                                      <span>
                                                                        Number of
                                                                        Vacancies:
                                                              </span>
                                                                      {
                                                                        job.NumberOfVacancies
                                                                      }
                                                                    </div>
                                                                    <div className="job-card-meta-item">
                                                                      <span>
                                                                        Available for
                                                                        remote workers:
                                                              </span>
                                                                      {job.IsRemoteJob ===
                                                                        "true" ||
                                                                        job.IsRemoteJob ===
                                                                        true ? (
                                                                          <span className="check-emoji">
                                                                            ✔️
                                                                          </span>
                                                                        ) : (
                                                                          <span className="cross-emoji">
                                                                            ❌
                                                                          </span>
                                                                        )}
                                                                    </div>
                                                                    <div className="job-card-meta-item">
                                                                      <span>
                                                                        Posting Date:
                                                              </span>
                                                                      {FormatDateTime(
                                                                        job.PostingDate
                                                                      )}
                                                                    </div>
                                                                    <div className="job-card-meta-item">
                                                                      <span>
                                                                        Updated Date:
                                                              </span>
                                                                      {FormatDateTime(
                                                                        job.UpdatedDate
                                                                      )}
                                                                    </div>
                                                                    <div className="job-card-meta-item">
                                                                      <span>
                                                                        Closing Date:
                                                              </span>
                                                                      {FormatDateTime(
                                                                        job.ExpiryDate
                                                                      )}
                                                                    </div>
                                                                  </div> */}
                                                          <div className="job-card-buttons-wrap">
                                                            <Link
                                                              className="job-card-button btn--secondary"
                                                              to={
                                                                siteConfig.companyCareersSite
                                                                  ? "/vacancies/vacancy/" +
                                                                    new Buffer(
                                                                      job.Id
                                                                    ).toString(
                                                                      "base64"
                                                                    ) +
                                                                    "/" +
                                                                    slugify(
                                                                      job.JobTitle
                                                                    ) +
                                                                    window
                                                                      .location
                                                                      .search
                                                                  : "/vacancies/" +
                                                                    apiKey +
                                                                    "/vacancy/" +
                                                                    new Buffer(
                                                                      job.Id
                                                                    ).toString(
                                                                      "base64"
                                                                    ) +
                                                                    "/" +
                                                                    slugify(
                                                                      job.JobTitle
                                                                    ) +
                                                                    window
                                                                      .location
                                                                      .search
                                                              }
                                                            >
                                                              More details
                                                            </Link>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    ))
                                                  ) : (
                                                    <div className="job-card card job-card-no-results">
                                                      <div className="job-card-details">
                                                        <div className="no-results">
                                                          Sorry, we don't have
                                                          any vacancies matching
                                                          your search.
                                                        </div>
                                                        <button
                                                          id="reset_vacs"
                                                          className="nav-action-sm"
                                                          onClick={() =>
                                                            resetVacs()
                                                          }
                                                        >
                                                          Reset
                                                        </button>
                                                      </div>
                                                    </div>
                                                  )}
                                                </>
                                              ) : (
                                                <div className="no-vacancies">
                                                  Loading vacancies...
                                                </div>
                                              )}

                                              <div className="load-more-wrap col-12">
                                                {currentCount < vacsCount && (
                                                  <a
                                                    className="nav-action-sm"
                                                    role="button"
                                                    onClick={() => loadMore()}
                                                  >
                                                    {loadMoreText}
                                                  </a>
                                                )}
                                              </div>
                                            </>
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              ) : (
                                <SmartFeedJobAlerts
                                  apiKey={apiKey}
                                  gdprKey={gdprKey}
                                  group={group}
                                  CompanyGroupName={CompanyGroupName}
                                  VacancyPageBaseURL={VacancyPageBaseURL}
                                  ReturnToSearchURL={ReturnToSearchURL}
                                  OptInLink={OptInLink}
                                  UnsubscribeLink={UnsubscribeLink}
                                  VacancyPageBaseURL={VacancyPageBaseURL}
                                  ReturnToSearchURL={ReturnToSearchURL}
                                  location={location}
                                  region={region}
                                  category={category}
                                  industry={industry}
                                  jobType={jobType}
                                  jobTime={jobTime}
                                />
                              )}
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </>
      ) : (
        <div className="page-loading-block">
          <div className="loading-panel">
            <div className="loading-ring">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
            <span className="loading-text">
              Please wait... If content is not shown soon then you may have the
              wrong URL.
            </span>
          </div>
        </div>
      )}
    </div>
  );
};

export default SmartFeedFiltersComponent;
